<template>
    <div>
        <editable-list
            ref="list"
            :data="identities"
            :fields="fields"
            track-by="id"
            @remove-row="unlinkIdentity"
        >
            <template v-slot:empty-result>
              <div>
                <Icon name="info" class="h-4 w-4 inline mr-1" />
                no cognito identities linked to this contact
              </div>
            </template>
            <template v-slot:row-template="">
                <Form :submit="find" @change.self="handleFormChange">
                    <template v-slot="props">
                        <div class="row-template">
                            <div class="form-row">
                                <div class="field-email">
                                    <TextField
                                        name="email"
                                        placeholder="email"
                                        :validate="email"
                                        :disabled="formValues.findById"
                                        :clearable="!formValues.findById" />
                                </div>
                                <div class="field-phone">
                                    <PhoneInput
                                        name="phone"
                                        placeholder="phone"
                                        :validate="usPhoneNumber"
                                        :disabled="formValues.findById"
                                        :clearable="!formValues.findById"
                                        ref="identityPhone"
                                    />
                                </div>
                                <div class="field-identityid">
                                    <div class="form-row" :style="{marginBottom: '0.5rem'}">
                                        <TextField
                                            name="identityId"
                                            placeholder="identity id"
                                            :validate="noSpaces"
                                            :disabled="!formValues.findById"
                                            :clearable="formValues.findById" />
                                    </div>
                                    <CheckboxInput name="findById" label="I know identity id" class="text-xs" />
                                </div>
                            </div>

                            <div class="flex form-row justify-end pl-2">
                                <button class="btn-primary btn-solid btn-small" :disabled="props.invalid || findDisabled">
                                  find <SpinLoader :loading="searching" class="ml-2"/>
                                </button>
                                <button
                                    class="btn-primary btn-small ml-4"
                                    type="button"
                                    @click.stop="closeRow"
                                >
                                  close
                                </button>
                            </div>

                            <div v-if="foundIdentities.length > 0" class="text-sm px-4">
                                <div class="flex items-center font-600 mb-2"><icon class="w-4 h-4 mr-1" name="info"/> the following records are found:</div>
                                <div v-for="foundIdentity of identitiesToLink" :key="foundIdentity.id" class="flex items-center mb-2">
                                    <span>{{foundIdentity.email}} {{foundIdentity.phone}}</span>
                                    <Tooltip :text="foundIdentity.isLinked ? 'identity is already linked' : null">
                                        <template v-slot:label>
                                            <button
                                                class="ml-2 btn-action"
                                                type="button"
                                                @click.stop="linkIdentity(foundIdentity)"
                                                :disabled="foundIdentity.isLinked"
                                            >
                                                link identity
                                            </button>
                                        </template>
                                    </Tooltip>
                                </div>
                            </div>
                            <div v-else-if="searchPerformed" class="text-sm px-4">
                                <template v-if="!formValues.findById && !!formValues.email">
                                    <div class="flex items-center font-600 mb-2">
                                        <icon class="w-4 h-4 mr-1" name="info"/>
                                        no records found. Do you want to create a new cognito identity to link to the person?
                                    </div>
                                    <div class="flex items-center mb-2">
                                      <span>{{formValues.email}} {{formValues.phone}}</span>
                                      <button
                                          class="ml-4 btn-action"
                                          type="button"
                                          @click.stop="addIdentity"
                                      >
                                        create and link
                                      </button>
                                    </div>
                                </template>
                                <div v-else class="flex items-center font-600">
                                    <icon class="w-4 h-4 mr-1" name="info"/> no records found. Try to search by email.
                                </div>
                            </div>
                        </div>
                    </template>
                </Form>
            </template>
            <template v-slot:actions="actionsScope">
                <ListActions>
                    <template v-slot:default="">
                        <button
                            class="menu-action"
                            type="button"
                            v-if="actionsScope.record.active && initLinkedIdentityIds.includes(actionsScope.record.identityId)"
                            @click.stop="resendInvitation(actionsScope.record)"
                        >
                          resend invitation
                        </button>
                        <button
                            class="menu-action"
                            type="button"
                            @click.stop="unlinkIdentity(actionsScope.record, actionsScope.index)"
                        >
                          unlink
                        </button>
                    </template>

                </ListActions>
            </template>
        </editable-list>
    </div>
</template>

<script>
import { cloneDeep, flatMap, uniqBy } from 'lodash-es';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import Icon from '@/components/ui/Icon';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import PhoneInput from '@/components/form/PhoneInput';
import EditableList from '@/components/ui/inlineediting/EditableList';
import TextEditableField from '@/components/auth/list/fields/TextEditableField';
import PhoneFieldEditable from '@/components/auth/list/fields/PhoneFieldEditable';
import CheckboxInput from '@/components/auth/form/CheckboxInput';
import ListActions from '@/components/auth/list/ListActions';
import Tooltip from '@/components/ui/Tooltip';
import SpinLoader from "@/components/ui/SpinLoader";

    export default {
        name: 'PersonCognitoIdentities',
        components: {
            SpinLoader,
            Tooltip,
            ListActions,
            CheckboxInput,
            Icon,
            Form,
            TextField,
            PhoneInput,
            EditableList,
        },
        mixins: [
            ConfirmationMixin,
            NotifyMixin,
            ValidatorMixin,
        ],
        props: {
            initialValues:{
                type: Array,
                default() {
                    return [];
                },
            },
        },
        emits: ['update', 'reinvite'],
        data() {
            return {
                identities: [],
                fields: [
                    {
                        name: TextEditableField,
                        title: 'email',
                        titleClass: 'relative pr-2',
                        dataClass: 'pr-2',
                        width: '32%',
                        switch: {
                            fieldName: 'email',
                            noClickOutside: true,
                            getValidator: () => this.required,
                            onUpdate: this.updateIdentityEmail,
                        },
                    },
                    {
                        name: PhoneFieldEditable,
                        title: 'phone',
                        titleClass: 'relative px-2',
                        dataClass: 'px-2',
                        width: '28%',
                        switch: {
                            noClickOutside: true,
                            getValidator: () => this.phoneNumber,
                            onUpdate: this.updateIdentityPhone,
                        },
                    },
                    {
                        name: 'identityId',
                        title: 'identity id',
                        titleClass: 'relative px-2',
                        dataClass: 'px-2 text-xs',
                        width: '30%',
                    },
                    {
                        name: 'actions',
                        title: '',
                        titleClass: 'relative px-2',
                        dataClass: 'px-2',
                        width: '10%',
                    }
                ],
                formValues: {},
                foundIdentities: [],
                searching: false,
                searchPerformed: false,
            };
        },
        computed: {
            personId() {
                return this.$route.params.personId;
            },
            findDisabled() {
              const { findById, email, phone, identityId } = this.formValues;
              return findById ? !identityId : !(email || phone);
            },
            identitiesToLink() {
                return this.foundIdentities.map((item) => ({
                    ...item,
                    isLinked: this.identities.find((i) => i.identityId === item.id)
                }));
            },
            initLinkedIdentityIds() {
                return this.initialValues.map(({ identityId }) => identityId);
            },
        },
        watch: {
            initialValues() {
                this.identities = cloneDeep(this.initialValues);
            },
            identities:  {
                handler: function () {
                    this.$emit('update', this.identities.map(({ identityId }) => identityId));
                },
                deep: true,
            }
        },
        methods: {
            addIdentity() {
                const { email, phone } = this.formValues;
                    this.createIdentity({ email, phone })
                        .then((record) => {
                            this.notifySuccess('cognito identity has been created and linked to the person. Please save changes');
                            this.linkIdentity(record);
                            this.closeRow();
                        })
                        .catch(error => this.notifyError(error.message));
            },

            find({ email, phone, findById, identityId}){
                this.foundIdentities = [];
                this.searchPerformed = false;
                if (findById) {
                    if (this.identities.find((identity) => identity.identityId === identityId)) {
                        this.notifyError('cognito identity with the provided identity id is already linked to the person');
                        return;
                    }
                    this.searching = true;
                    this.findIdentityById(identityId)
                        .then((result) => {
                            this.foundIdentities = [result];
                            this.searchPerformed = true;
                        })
                        .catch(() => {
                            this.notifyError('cognito identity with the provided id is not found')
                        })
                        .finally(() => {
                            this.searching = false;
                        });
                } else {
                    this.searching = true;
                    this.findIdentityByEmailOrPhone({ email, phone })
                        .then((result) => {
                            this.foundIdentities = result;
                            this.searchPerformed = true;
                        })
                        .finally(() => {
                            this.searching = false;
                        });
                }
            },

            closeRow() {
                this.$refs.list.hideNewRow();
                this.foundIdentities = [];
                this.searchPerformed = false;
            },

            createIdentity({ email, phone }) {
                return this.$raaDataProvider.create('identities', { data: { email, phone } });
            },

            findIdentityByEmailOrPhone({ email, phone }) {
                const searchRequests = [];
                if (email) {
                    searchRequests.push(this.$raaDataProvider.getList('identities', { email }));
                }
                if (phone) {
                    searchRequests.push(this.$raaDataProvider.getList('identities', { phone }));
                }
                return Promise.all(searchRequests).then((result) => {
                    return uniqBy(flatMap(result, ({ content }) => content), 'id');
                });
            },

            findIdentityById(id) {
                return this.$raaDataProvider.getOne('identities', { id });
            },

            handleFormChange(state) {
              this.formValues = state.values;
            },

            linkIdentity(record) {
                if (this.identities.find((identity) => identity.email === record.email)) {
                    this.notifyError('cognito identity with the provided email is already linked to the person');
                    return;
                }
                this.identities.push({
                    cognitoIdentityId: record.cognitoIdentityId,
                    email: record.email,
                    identityId: record.id,
                    phone: record.phone ?? '',
                    active: record.active,
                });
                this.notifySuccess('cognito identity has been linked to the person. Please save changes');
            },

            resendInvitation({ identityId }) {
              this.$emit('reinvite', identityId);
            },

            unlinkIdentity({ identityId } = {}, index) {
                this.requestConfirmation({
                    confirmationMessage: 'are you sure you want to unlink this cognito identity?',
                    confirmBtnText: 'yes',
                    cancelBtnText: 'no',
                    confirmationType: 'warning'
                })
                    .then(confirmed => {
                        if (!confirmed) {
                          return null;
                        }

                        if (!this.person) {
                            // 'create a person' flow
                            this.identities.splice(index,1);
                            return;
                        }

                        this.$raaDataProvider.create('unlinkIdentity', { identityId, personId: this.personId })
                            .then(() => {
                                this.notifySuccess('identity has been unlinked');
                                this.identities.splice(index,1);
                            })
                            .catch(error => this.notifyError(error.message));
                    });
            },

            updateIdentity({ identityId, email, phone }) {
                return this.$raaDataProvider.update('identities', { id: identityId, data: { email, phone: phone || null } });
            },

            updateIdentityEmail(email, { data, index, ref }) {
                const { identityId, phone } = data;
                this.requestConfirmation({
                    confirmationMessage: 'are you sure you want to update cognito identity email?',
                    confirmBtnText: 'yes',
                    cancelBtnText: 'no',
                    confirmationType: 'success'
                })
                    .then(confirmed => {
                        if (!confirmed) {
                            ref.cancel();
                            return null;
                        }
                        this.updateIdentity({ identityId, email, phone })
                            .then(() => {
                                this.notifySuccess('identity email has been updated');
                                this.identities[index].email = email;
                                ref.closeEditMode();
                            })
                            .catch(error => this.notifyError(error.message));
                    });
            },

            updateIdentityPhone(phone, { data, index, ref }) {
                const { identityId, email } = data;
                this.requestConfirmation({
                    confirmationMessage: 'are you sure you want to update cognito identity phone?',
                    confirmBtnText: 'yes',
                    cancelBtnText: 'no',
                    confirmationType: 'success'
                })
                    .then(confirmed => {
                        if (!confirmed) {
                            ref.cancel();
                            return null;
                        }
                        this.updateIdentity({ identityId, email, phone })
                            .then(() => {
                                this.notifySuccess('identity phone has been updated');
                                this.identities[index].phone = phone;
                                ref.closeEditMode();
                            })
                            .catch(error => this.notifyError(error.message));
                    });

            },
        },
    };
</script>
<style scoped>
    .row-template :deep(.form-col) {
        @apply mx-0;
    }
    .row-template :deep(.field-email) {
        @apply pl-4 pr-2;
        width: 32%;
    }
    .row-template :deep(.field-phone) {
        @apply px-2;
        width: 28%;
    }
    .row-template :deep(.field-identityid) {
      @apply pl-2 pr-4;
      width: 40%;
    }
    .menu-action {
        @apply flex p-3 w-full;

      &:hover {
         @apply bg-active-100;
       }
    }
</style>
